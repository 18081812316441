@import '~normalize.css/normalize.css';
@import '~simplebar/dist/simplebar.min.css';

html {
  --scrollbar-padding: 0rem;
}

body,
html {
  cursor: default;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

* {
  text-rendering: geometricPrecision;
  cursor: inherit;
  box-sizing: border-box;
  min-width: 0; // fixes grid overgrowth, normalizes behaviour across browsers
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:focus {
    outline: none;
  }
}

a,
button {
  color: inherit;
  text-decoration: inherit;

  &:not([disabled]) {
    cursor: pointer;
  }
}
input,
textarea,
::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
}

input,
textarea {
  max-width: 100%;
  box-sizing: border-box;
}

strong {
  font-weight: 600;
}

input,
textarea,
::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
}

input,
textarea {
  max-width: 100%;
  box-sizing: border-box;
}

p {
  margin: 0;
}

.simplebar-content-wrapper {
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

figure {
  margin: 0;
  padding: 0;
}
